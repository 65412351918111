<template>
  <div>
    <message-bar
        v-show="detail.cashOutData.readyMoney > 0"
        :content="
        '您有' + toDecimal2(detail.cashOutData.readyMoney) + '元收入待确认'
      "
        button-text="去确认账单"
        @click-button="$router.push('/confirm')"
    ></message-bar>
    <el-row>
      <el-col :span="18">
        <div style="padding: 20px 22px 0 22px;background-color: white;border-radius: 4px">
          <el-row :gutter="20">
            <el-col :span="8">
              <income-card
                  :image="img3"
                  :number="detail.cashOutData.doneMoney | toDecimal2"
                  title="可提现收益"
                  style="background-image: linear-gradient(to right, rgba(255,0,77,0.1) , rgba(255,0,77,0.045));">
                <el-button
                    size="small"
                    style="margin-top: 14px; padding: 8px 15px; border-radius: 0; font-size:12px;"
                    type="primary"
                    @click="jumper">
                  去提现
                </el-button>
              </income-card>
            </el-col>
            <el-col :span="8">
              <income-card
                  :image="img2"
                  :number="detail.cashOutData.allMoney | toDecimal2"
                  title="累计收益"
              >
                <div style="display: flex; font-size: 15px; line-height: 1.9">
                  <div
                      style="margin-top: 19px; cursor: pointer; color: #ff004d; font-size:12px"
                      @click="$router.push('/bills')"
                  >
                    查看>
                  </div>
                </div>
              </income-card>
            </el-col>
            <el-col :span="8">
              <income-card
                  :image="img1"
                  :number="detail.cashOutData.residueAmount | toDecimal2"
                  title="剩余预付"
              >
                <!--          <div @click="() => {}" style="margin-top: 19px">查看></div>-->
              </income-card>
            </el-col>
          </el-row>
        </div>

      </el-col>
      <el-col :span="6" style="padding-left: 20px">
        <bank-card :card-info="cardInfo"></bank-card>
      </el-col>
    </el-row>

    <div class="white-board">
      <div style="display: flex; justify-content: space-between">
        <div class="title">收益统计<span style="font-size: 14px;color: #555555;float: none">/元</span></div>
        <el-select
            v-model="timeRange"
            size="small"
            @change="getStatisticsByTime().then(() => getStatisticsByTimeAndType())"
        >
          <el-option
              v-for="item in [
              { label: '近三个月', value: '0' },
              { label: '近半年', value: '1' },
              { label: '近一年', value: '2' },
            ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="statistics-type">
        <div
            v-for="i in lineType"
            :key="i.id"
            :class="{ active: statisticsId === i.label }"
            @click="lineTypeClick(i.label)"
        >
          <div class="name">{{ i.value }}</div>
          <div class="money">
            {{ (i.totalMoney || '0.00') | toDecimal2 }}
          </div>
        </div>
      </div>

      <div style="width: 100%;display: flex;justify-content: center">
        <div id="chart1" style = "width:100%; height: 400px"></div>
      </div>
    </div>

    <div class="white-board">
      <div class="title">收益分布</div>
      <div style="width: 100%;display: flex;justify-content: center">
        <div id="chart2" style="width: 730px; height: 300px"></div>
      </div>
    </div>

    <div class="white-board">
      <div class="title">
        最新账单 <span @click="$router.push('/bills')">更多</span>
      </div>
      <bill-table :page-size="5" not-show-pagination></bill-table>
    </div>
  </div>
</template>

<script>
/**
 * 我的收益
 * /Income
 */
import MessageBar from "@/views/income/components/MessageBar"
import IncomeCard from "@/views/income/components/IncomeCard"
import img1 from "@/assets/income/income-card-1.png"
import img2 from "@/assets/income/income-card-2.png"
import img3 from "@/assets/income/income-card-3.png"
import BankCard from "@/views/income/components/BankCard"
import wallet from "@/api/wallet"
import {toDecimal2} from "@/utils/utils"

import * as echarts from 'echarts';
import {dictInfo} from "@/api"
import BillTable from "@/views/income/components/BillTable"

export default {
  name: "Income",
  components: {
    MessageBar,
    IncomeCard,
    BankCard,
    BillTable
  },
  data() {
    return {
      toDecimal2,
      img1,
      img2,
      img3,
      timeRange: '1',
      incomeType: '',
      detail: {
        "cashOutData": {
          "allMoney": 0,
          "deductAmount": 0,
          "doneMoney": 0,
          "nowMoney": 0,
          "readyMoney": 0,
          "residueAmount": 0,
          "totalMoney": 0
        },
        "incomeList": [
          {
            "income": 0,
            "month": 0
          }
        ],
        "totalIncome": 0
      },
      statisticsByCompany: [],

      cardInfo: {
        status: null
      },

      // 折线图
      lineType: [],
      statisticsByTime: [],
      statisticsId: '',

      chart1: null,
      chart1Option: {
        grid:{
          left: 60,
          width: '100%',
        },
        color: '#ff004d',
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          axisLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              color: 'rgba(231,232,242,1)'

            },

          },
          axisLabel: {
            textStyle: {
              opacity: 0.4,
              background: '#535875',
              fontSize: '12px',
              fontFamily: 'Rubik, Rubik-Regular',
              fontWeight: 400,
              textAlign: 'RIGHT',
              color: '#535875'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '￥{value}'
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#DDDDDD',
              width:.5
            }
          },

        },
        series: [
          {
            name: '版税收益',
            type: 'line',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          },
        ]
      },

      // 饼图
      chart2: null,
      chart2Option: {
        color: ['#79A4FA', '#A78BD4', '#7BE0B7', '#EB6565'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          align: 'left',
          left: 'right',
          top: 'middle',
          itemGap: 20,
          itemWidth: 9,
          itemHeight: 9,
          icon: 'circle'
        },
        series: [
          {
            type: 'pie',
            radius: '75%',
            left: 'left',
            label: {
              formatter: '{b} \n ￥{c}'
            },
            labelLine: {
              lineStyle: {
                color: '#ABABAB'
              }
            },
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      // 表格头部样式
      headerStyle: {
        background: '#f8f8f8',
        fontSize: '14px',
        fontFamily: 'font-family: PingFang SC, PingFang SC-Medium;',
        fontWeight: 400,
        color: '#333333'
      }
    }
  },
  mounted() {
    this.chart1 = echarts.init(document.getElementById('chart1'))
    this.chart1.setOption(this.chart1Option)
    this.chart2 = echarts.init(document.getElementById('chart2'))

    this.getMyBankMsg()
    this.getDetail()

    this.getLineType().then(() => {
      this.getStatisticsByCompany()
      this.getStatisticsByTime().then(() => {
        this.getStatisticsByTimeAndType()
      })
    })
  },
  methods: {
    lineTypeClick(label) {
      this.statisticsId = label
      this.getStatisticsByTimeAndType()
    },
    getLineType() {
      return dictInfo({codes: ["10016"]}).then(r => {
        this.lineType = r.data ? r.data : []
        this.statisticsId = this.statisticsId === '' && this.lineType.length > 0 ? this.lineType[0].label : ''

        let t = []
        this.lineType.forEach(s => {
          t.push({
            value: 0,
            name: s.value
          })
        })
        this.chart2Option.series[0].data = t

      })
    },
    getMyBankMsg() {
      wallet.myBankMsg(this.$store.state.usermsg.webLoginType === 0 ? 1 : 0).then(r => {
        // 没有银行卡
        if (r.data === null) {
          this.cardInfo = {
            status: null
          }
        } else {
          this.cardInfo = r.data
        }
      })
    },
    getDetail() {
      wallet.detail().then(r => {
        this.detail = r
      })
    },
    getStatisticsByTime() {
      return wallet.statisticsByTime(this.timeRange).then(r => {
        this.statisticsByTime = r.data
        this.lineType.forEach(f => {
          f.totalMoney = 0
          this.statisticsByTime.forEach(e => {
            if (e.type === f.label) {
              f.totalMoney = e.totalMoney
            }
          })
        })
        // shit
        this.lineType = [...this.lineType]
      })
    },
    getStatisticsByTimeAndType() {
      wallet.statisticsByTimeAndType({
        "timeValue": this.timeRange,
        "type": this.statisticsId
      }).then(r => {
        let xAxis = []
        let data = []
        if (r.data && r.data.length > 0) {
          r.data.forEach(e => {
            xAxis.push(e.time)
            data.push(e.totalMoney)
          })
          this.chart1Option.xAxis.data = xAxis
          this.chart1Option.series[0].data = data
        } else {
          this.chart1Option.xAxis.data = ['', '', '', '', '', '', '', '', '', '']
          this.chart1Option.series[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }

        this.chart1.clear()
        this.chart1.setOption(this.chart1Option)
      })
    },
    getStatisticsByCompany() {
      wallet.statisticsByCompany().then(r => {
        this.statisticsByCompany = r.data
        this.statisticsByCompany.forEach(s => {
          this.chart2Option.series[0].data.forEach(e => {
            if (s.name === e.name) {
              e.value = toDecimal2(s.totalMoney)
            }
          })
        })
        this.chart2.setOption(this.chart2Option)
      })
    },
    jumper() {
      if (this.cardInfo.status === null) {
        this.$popup({msg: '您还未绑定银行卡，绑定后即可打款哦~', type: 'warning'});
        return
      } else if (this.cardInfo.status === 0) {
        this.$popup({msg: '银行卡审核中，请等待审核通过后再进行提现操作', type: 'warning'})
        return
      } else if (this.cardInfo.status === 2) {
        this.$popup({msg: '您的银行卡审核失败，重新绑定后即可提现哦~', type: 'error'})
        return
      }
      this.$router.push('cash-out-list')
    },
  },
}
</script>

<style lang="scss" scoped>
.white-board {
  background-color: white;
  border-radius: 2px;
  margin-bottom: 20px;
  padding: 20px 30px;

  .title {
    font-size: 18px;
    font-weight: 500;
    color: #333333;

    span {
      float: right;
      cursor: pointer;
      color: #ff004d;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
    }
  }

  .statistics-type {
    margin-top: 15px;
    display: flex;

    > div {
      background-color: #f8f8f8;
      width: 106px;
      height: 42px;
      margin-right: 16px;
      padding: 10px 16px;
      cursor: pointer;
      border: 1px solid transparent;

      .name {
        font-size: 12px;
        color: #666666;
      }

      .money {
        font-size: 14px;
        color: #333333;
        line-height: 1.7;
      }
    }

    .active > .name,
    .active > .money {
      color: #ff004d;
    }

    > div:hover,
    .active {
      background: rgba(255, 0, 77, 0.05);
      border: 1px solid #ff004d;
      border-radius: 2px;
      color: #ff004d;
    }
  }
}

::v-deep .el-select {
  box-sizing: border-box;
  width: 135px;

  .el-input__inner {
    padding-left: 16px;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
  }
}

::v-deep .el-link--inner {
  color: #FF477E;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #ff477e;
}
</style>
