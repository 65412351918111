import {
  $post,
  // $get
} from './index'

export default {
  myBankMsg(val){
    return $post('/api/wallet/myBankMsg?type='+val)
  },
  // moneyAndIncomeDetail(){
  //   return $post('/api/wallet/moneyAndIncomeDetail?pageSize=1000&pageNum=1')
  // },
  detail(){
    return $post('api/wallet/detail')
  },

  /**
   * /api/wallet/statisticsByTime
   * 钱包-账单统计数据2-根据时间查询(收益类型总计)
   * @returns {Promise<*>}
   */
  statisticsByTime(timeValue){
    return $post('api/wallet/statisticsByTime', {
      timeValue
    })
  },

  /**
   * /api/wallet/statisticsByTimeAndType
   * 钱包-账单统计数据2-根据时间查询(收益类型图表)
   * @param data
   * @returns {Promise<*>}
   */
  statisticsByTimeAndType(data){
    return $post('api/wallet/statisticsByTimeAndType', data)
  },


  /**
   * 钱包-提现-账单统计数据
   * /api/wallet/statisticsByCompany
   * @returns {Promise<*>}
   */
  statisticsByCompany(){
    const data = {
      "dimension": 1,
      "timeValue": 0,
      "type": 1
    }
    return $post('api/wallet/statisticsByCompany', data)
  },

  /**
   * /api/wallet/incomeList
   * 钱包-入账记录
   * @returns {Promise<*>}
   */
  incomeList(data){
    return $post('api/wallet/incomeList',data)
  },

  incomeListUnchecked(){
    return $post('api/wallet/incomeList', {status: 0})
  },

  /**
   * /api/wallet/incomeDetailCheck
   * 钱包-账单确认
   * @param ids
   * @returns {Promise<*>}
   */
  incomeDetailCheck(ids){
    return $post('/api/wallet/incomeDetailCheck?ids=' + ids)
  },

  /**
   * /api/wallet/withdraw
   * 提现
   * @param data
   * @returns {Promise<*>}
   */
  withdraw(data){
    return $post('api/wallet/withdraw', data)
  },
//  公司抬头信息
  wallet_getRiseByIncome(params){
    return $post('/api/wallet/getRiseByIncome?id='+params)
}
}
