<template>
  <div class="bank-card" @click="setbankcard">
<!--    状态:-1删除;0待审核;1审核通过;2审核失败-->
  <div class="title">我的银行卡</div>
    <div class="content" v-if="cardInfo.status === 1">
      {{cardInfo.bankName}}({{cardInfo.bankCard.slice(cardInfo.bankCard.length - 4, cardInfo.bankCard)}})
    </div>
    <div class="content" v-else>
      {{getTitleByStatus()}}
    </div>
  <div class="addBank" :class="{active:cardInfo.status === null}">{{cardInfo.status === null ? '+ 添加银行卡':'设置>'}}</div>
  </div>
</template>

<script>
import bankCardBackground from '@/assets/income/bank-card.png'
import bankCard1Background from '@/assets/income/bank-card-1.png'
export default {
  name: "BankCard",
  props: {
    cardInfo: {
      type: [Object, null],
      default: null
    }
  },
  data() {
    return {
      bankCardBackground,
      bankCard1Background
    }
  },
  methods: {
    getTitleByStatus(){
      switch (this.cardInfo.status) {
        case 0: return '银行卡审核中...'
        case 2: return '银行卡审核失败!'
        default: return '您还没有绑定银行卡'
      }
    },
    getSubtitleByStatus(){
      switch (this.cardInfo.status) {
        case 0: return '审核通过后即可申请提现~'
        case 2: return '重新绑定后即可申请提现~'
        default: return '绑定后即可申请提现~'
      }
    },
    setbankcard(){
      console.log(this.cardInfo.status,'status')
        if(this.cardInfo.status ===1||this.cardInfo.status ===2 ||this.cardInfo.status === null || this.cardInfo.status === 0){
            this.$router.push(`/setbankcard/Msmchecking/${this.$store.state.usermsg.webLoginType}`)
        }
    }
  }
}
</script>

<style scoped lang="scss">
.bank-card{
  cursor: pointer;
  background-color: white;
  margin-bottom: 20px;
  height: 140px;
  background-size: cover;
  border-radius: 4px;
  padding: 20px 24px;
  background-image: url('../../../assets/income/bank-card2.png');
 background-size: 100% 100%;
  .title{
    font-size: 14px;
    font-family:PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #333333;
  }
  .content{
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #666666;
    margin-top: 27px;
    margin-bottom: 15px;
  }
  .addBank{
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Light;
    font-weight: 300;
    text-align: LEFT;
    color: #ff004d;
    margin-top: 24px;
  }
  .active{
    width: 120px;
    height: 34px;
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    line-height: 34px;
    text-align: center;
    color: #666666;
  }
}
</style>
