import { render, staticRenderFns } from "./IncomeCard.vue?vue&type=template&id=183fd12f&scoped=true&"
import script from "./IncomeCard.vue?vue&type=script&lang=js&"
export * from "./IncomeCard.vue?vue&type=script&lang=js&"
import style0 from "./IncomeCard.vue?vue&type=style&index=0&id=183fd12f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "183fd12f",
  null
  
)

export default component.exports