<template>
    <div class="box">
      <el-row :gutter="10" justify="space-between">
        <el-col :span="12" style="display: flex;align-items: center;">
          <el-image :src="icon" style="width: 22px;height: 22px;margin-right: 8px"></el-image>
          <span class="content">{{ content }}</span>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button style="padding: 6px 10px;border-radius: 2px;" @click="$emit('click-button')">{{ buttonText }}</el-button>
        </el-col>
      </el-row>
    </div>
</template>

<script>
import icon from '@/assets/income/message-bar-icon.png'
export default {
  name: "MessageBar",
  props: {
    content: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      icon
    }
  }
}
</script>

<style scoped lang="scss">
.box{
  background: #ffeecd;
  border-radius: 4px;
  padding: 15px 20px;
  margin-bottom: 20px;
  span.content{
    font-size: 14px;
    line-height: 2;
    font-weight: 500;
    color: #333333;
  }
  .el-button{
    border: none;
    border-radius: 4px;
  }
}
</style>
