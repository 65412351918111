<template>
  <div class="box">
    <el-row>
      <el-col :span="12">
        <div class="header">{{ title }}</div>
        <div class="number">{{ number }}</div>
      </el-col>
      <el-col :span="12" v-if="image !== ''" style="text-align: right">
        <el-image :src="image" style="width: 50px;height: 50px"></el-image>
      </el-col>
    </el-row>
    <el-row>
     <el-col :span="24">
       <slot></slot>
     </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: "IncomeCard",
  props: {
    title: {
      type: String,
      default: ''
    },
    number: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.box{
  background-color: #fafafa;
  padding: 21px 24px;
  margin-bottom: 20px;
  border-radius: 4px;
  min-height: 101px;
  .number{
    margin-top: 7px;
    font-size: 22px;
    font-weight: 600;
    color: #333333;
  }
  .header{
    line-height: 1;
    font-size: 14px;
    font-family:PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
  }
  .header::after{
    content: '/元';
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    font-family:PingFang SC-Medium;
  }
}

</style>
